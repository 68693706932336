/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';

import { UseMutationOptionsType } from '@mwell-healthhub/commons';

import { exportProfileClients, scanDigitalId } from '../../services/digitalIdService';

export const useScanDigitalId = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(scanDigitalId, mutationOptions);

export const useExportProvideClients = (mutationOptions?: UseMutationOptionsType) =>
  useMutation(exportProfileClients, mutationOptions);
